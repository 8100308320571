import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './milestone.css';

// Icons
import CustomersIcon from './images/customers-icon.png';
import DockerIcon from './images/docker-icon.png';
import GitHubIcon from './images/github-icon.png';
import SpeedIcon from './images/speed-icon.png';

const milestoneIcon = (icon) => {
  if (icon === 'customers') return CustomersIcon;
  if (icon === 'docker') return DockerIcon;
  if (icon === 'github') return GitHubIcon;
  if (icon === 'speed') return SpeedIcon;
  return null;
};

const Milestone = ({ className: additionalClassName, alt, img, title, withDivisor, children }) => (
  <div
    className={classNames(
      'flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4 pt-4 pb-4',
      withDivisor && 'gray-top-sep',
      additionalClassName
    )}
  >
    <div className="icon-wrapper">
      <img src={milestoneIcon(img)} alt={alt} />
    </div>
    <div>
      <h3>{title}</h3>
      {children}
    </div>
  </div>
);

Milestone.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  img: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  withDivisor: PropTypes.bool,
};

Milestone.defaultProps = {
  alt: 'Image',
  className: null,
  img: null,
  title: null,
  children: null,
  withDivisor: false,
};

export default Milestone;
