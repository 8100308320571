import classNames from 'classnames';
import React, { useState } from 'react';

import Button from 'components/shared/button';
import Field from 'components/shared/field';
import TextArea from 'components/shared/textarea';
import useCases from 'constants/use-cases';
import { sendContactEmail } from 'utils/api';
import { formSubmitEvent } from 'utils/keys';
import { unwrapDOMEvent, validEmail } from 'utils/utils';

const subjects = [
  {
    value: 'general',
    text: 'General Questions',
  },
  {
    value: 'licensing',
    text: 'Licensing / enterprise',
  },
  {
    value: 'support',
    text: 'Support',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

const hostingOptions = [
  {
    value: 'usage',
    text: 'Cloud Unit',
  },
  {
    value: 'dedicated',
    text: 'Dedicated',
  },
  {
    value: 'self',
    text: 'Self-hosting',
  },
];

const ContactForm = () => {
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [useCase, setStateUseCase] = useState(useCases[0].value);
  const [hosting, setStateHosting] = useState(hostingOptions[0].value);
  const [subject, setStateSubject] = useState(subjects[0].value);

  const setUseCase = (useCase) => setStateUseCase(useCase);
  const setHosting = (hosting) => setStateHosting(hosting);
  const setSubject = (subject) => setStateSubject(subject);
  const onSetCompany = unwrapDOMEvent(setCompany);
  const onSetEmail = unwrapDOMEvent(setEmail);
  const onSetFullName = unwrapDOMEvent(setFullName);
  const onSetMessage = unwrapDOMEvent(setMessage);
  const onSetUseCase = unwrapDOMEvent(setUseCase);
  const onSetSubject = unwrapDOMEvent(setSubject);
  const onSetHosting = unwrapDOMEvent(setHosting);

  const hideHostingOptions = () => subject !== 'licensing' && subject !== 'support';

  const resetFields = () => {
    setIsBusy(false);
    setCompany('');
    setEmail('');
    setFullName('');
    setMessage('');
    setError('');
    setStateUseCase(useCases[0].value);
    setStateHosting(hostingOptions[0].value);
    setStateSubject(subjects[0].value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccessMsg('');
    setIsBusy(true);

    const fields = {
      email,
      fullName,
      company,
      subject,
      message,
    };

    if (!hideHostingOptions()) {
      // These have a val even if they're hidden. Not sending them if not needed
      fields.hosting = hosting;
      fields.useCase = useCase;
    }

    sendContactEmail(fields)
      .then((data) => {
        const success = data?.contact?.wasSent;
        if (success) {
          // eslint-disable-next-line no-undef
          gtag('event', formSubmitEvent, { data: subject });
          setSuccessMsg('Message sent!');
          resetFields();
          return;
        }
        setError('Something is not right... Please try again later.');
        setIsBusy(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsBusy(false);
      });
  };

  const hasErrors = () => !fullName || !validEmail(email) || !subject || !message;

  return (
    <div className="col-span-8 xl:col-span-8 lg:col-span-12 lg:flex-col lg:justify-between md:mb-10 bg-primary-white pt-10 pb-10 px-6 border border-grey-20">
      <p>
        Message our team about commercial licenses or to discuss any weird and wonderful details
        about your automation requirements.
      </p>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative !mx-0 my-2"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      {successMsg && (
        <div className="success-msg px-4 py-3 rounded relative !mx-0 my-2" role="alert">
          <span className="block sm:inline">{successMsg}</span>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4 pt-8">
          <Field
            autoComplete="full-name"
            className="w-full"
            id="full-name"
            label="Full Name"
            fieldType="input"
            type="text"
            style={{ font: 'small-caption' }}
            placeholder="John Doe"
            value={fullName}
            isRequired
            onChange={onSetFullName}
          />
          <Field
            autoComplete="company"
            className="w-full"
            id="company"
            label="Company"
            fieldType="input"
            type="text"
            style={{ font: 'small-caption' }}
            value={company}
            onChange={onSetCompany}
          />
        </div>
        <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4 pt-4">
          <Field
            autoComplete="email"
            className="w-full"
            id="email"
            label="Email"
            fieldType="input"
            type="text"
            style={{ font: 'small-caption' }}
            placeholder="your@email.com"
            value={email}
            isRequired
            onChange={onSetEmail}
          />
          <Field
            autoComplete="subject"
            className="w-full"
            id="subject"
            label="Subject"
            fieldType="select"
            options={subjects}
            style={{ font: 'small-caption' }}
            value={subject}
            isRequired
            onChange={onSetSubject}
          />
        </div>
        <div
          className={classNames(
            'flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4 pt-4',
            hideHostingOptions() && 'hidden'
          )}
        >
          <Field
            className="w-full"
            id="hosting"
            label="Hosting Option"
            fieldType="select"
            options={hostingOptions}
            style={{ font: 'small-caption' }}
            value={hosting}
            onChange={onSetHosting}
          />
          <Field
            className="w-full"
            id="useCase"
            label="Use Case"
            fieldType="select"
            options={useCases}
            style={{ font: 'small-caption' }}
            value={useCase}
            onChange={onSetUseCase}
          />
        </div>
        <div className="flex mb-4 space-x-5 sm:flex-col sm:space-x-0 sm:space-y-4 pt-4">
          <TextArea
            className="w-full"
            id="message"
            label="Message"
            fieldType="input"
            type="textarea"
            style={{ font: 'small-caption' }}
            value={message}
            isRequired
            onChange={onSetMessage}
          />
        </div>

        <Button
          className="font-bold leading-none text-center whitespace-nowrap bg-primary-black text-primary-white"
          size="md"
          theme="primary-black-filled"
          type="submit"
          disabled={hasErrors() || isBusy}
        >
          Send
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
