import classNames from 'classnames';
import React from 'react';

import Aside from 'components/pages/contact/aside';
import Form from 'components/pages/contact/form';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { margins, paddings } from 'constants/spacing';
import MainLayout from 'layouts/main';

const labels = {
  header: 'Reach out to us',
};

const ContactPage = () => (
  <MainLayout headerTheme="grey-5">
    <section className={classNames('pt-20 xl:pt-16 md:pt-10 bg-grey-5', paddings.bottom.lg)}>
      <Container>
        <Heading className="text-left" tag="h1" size="xl" theme="primary-black">
          {labels.header}
        </Heading>

        <div
          className={classNames(
            'grid grid-cols-12 gap-x-8 xl:gap-x-7 lg:gap-x-4 md:block',
            margins.top.sm
          )}
          id="contact"
        >
          <Form />
          <Aside />
        </div>
      </Container>
    </section>
  </MainLayout>
);

export default ContactPage;
