import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './textarea.css';
import Asterisk from './images/asterisk.inline.svg';

const TextArea = ({
  className: additionalClassName,
  id,
  label,
  isRequired,
  isInvalid,
  ...otherProps
}) => (
  <div className={classNames('relative', additionalClassName)}>
    <label
      className={classNames('flex text-sm leading-none', isInvalid && 'text-state-error')}
      htmlFor={id}
    >
      {label}
      {isRequired && (
        <Asterisk
          className={classNames('ml-1', isInvalid ? 'text-state-error' : 'text-secondary-pink')}
        />
      )}
    </label>
    <textarea
      className={classNames(
        'flex w-full h-12 border bg-primary-white px-4 outline-none text-base transition-colors duration-200 placeholder-grey-20 mt-2',
        isInvalid ? 'border-state-error' : 'border-grey-60 focus:border-secondary-blue',
        additionalClassName
      )}
      {...otherProps}
    />
  </div>
);

TextArea.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

TextArea.defaultProps = {
  className: null,
  isInvalid: false,
  isRequired: false,
};

export default TextArea;
