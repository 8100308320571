import React from 'react';

import Link from 'components/shared/link';

import Milestone from '../milestone';

const Aside = () => (
  <div className="col-span-4 xl:col-span-4 lg:col-span-12 lg:flex lg:items-center lg:justify-between md:mb-10 bg-primary-white pt-6 px-6 border border-grey-20 milestone-aside">
    <Milestone img="docker" alt="Docker Icon" title="+160M pulls">
      <p>
        in our{' '}
        <Link
          className="mt-6 xl:mt-4 pink-anchor"
          to="https://hub.docker.com/r/browserless/chrome"
          target="_blank"
          rel="noopener noreferrer"
        >
          Docker Image
        </Link>
        .
      </p>
    </Milestone>
    <Milestone img="github" alt="GitHub Icon" title="+6k stars" withDivisor>
      <p>
        in our{' '}
        <Link
          className="mt-6 xl:mt-4 pink-anchor"
          to="https://github.com/browserless/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub Repo
        </Link>
        .
      </p>
    </Milestone>
    <Milestone img="customers" alt="GitHub Icon" title="+10k customers" withDivisor>
      <p>trusting us worldwide.</p>
    </Milestone>
    <Milestone img="speed" alt="GitHub Icon" title="Fastest & most secure" withDivisor>
      <p>headless browser!</p>
    </Milestone>
  </div>
);

export default Aside;
